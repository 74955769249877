import * as React from 'react'
import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page-title/page-title'
import Button from '../components/button/button'
import TeamMember from '../components/team-member/team-member'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import * as styles from '../styles/about.module.css'

const AboutPage = ({ data }) => {
    return (
        <Layout>
            <div className={'text-center'}>
                <PageTitle
                    superTitle='ABOUT THIN EDGE LABS'
                    title={<>Our <span>guiding principle</span> in all our products is “simplicity leads to a superior user experience”.</>}
                    subTitle={<><strong>Don’t just take our word for it</strong>, experience it for yourself by trying any of our products.</>}
                />
                <div className='text-center mb-5'>
                    <p className='mt-4 text-secondary'>
                        We're a team of individuals with expertise in blockchain, software development and design, crafting solutions for the next billion blockchain users.
                        <br />
                    </p>
                    <div className={['row', styles.teamMembers].join(' ')}>
                        <TeamMember name='Floor de Best' founder={true} role='Full-Stack Developer' className='col-sm-6 col-md-4 mb-3' image={getImage(data.floor)} />
                        <TeamMember name='Radu Ciobanu' founder={true} role='Full-Stack Developer' className='col-sm-6 col-md-4 mb-3' image={getImage(data.radu)} />
                        <TeamMember name='Raul Canta' founder={true} role='UI/UX Designer' className='col-sm-6 col-md-4 mb-3' image={getImage(data.raul)} />
                    </div>
                    <Button text='Contact Us' url='/contact' className={styles.contactUs} />
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    radu: file(relativePath: {eq: "images/team/radu.png"}) {
        childImageSharp {
            gatsbyImageData (
                width: 160
            )
        }
    },
    floor: file(relativePath: {eq: "images/team/floor.png"}) {
        childImageSharp {
            gatsbyImageData (
                width: 160
            )
        }
    },
    raul: file(relativePath: {eq: "images/team/raul.png"}) {
        childImageSharp {
            gatsbyImageData (
                width: 160
            )
        }
    }
  }
`

export const Head = ({ location }) => <Seo title="About" locationPath={location.pathname} />


export default AboutPage