import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './team-member.module.css'

const TeamMember = ({ name, founder, role, image, className }) => {
    return (
        <div className={className}>
            <div className={styles.box}>
                {<GatsbyImage image={image} alt={name} loading='eager' placeholder='blurred' />}
                <div className={styles.title + ' mt-4'}>{name}</div>
                <div className={styles.body}>
                    {founder && <span className='text-secondary'>Co-Founder</span>}, {role}
                </div>
            </div>
        </div >
    )
}

export default TeamMember