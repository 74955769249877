import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Button = ({ icon, text, url, target, className }) => {
    const classes = 'btn btn-primary position-relative' + (className ? ' ' + className : '')
    return (
        <a
            href={url}
            target={target || '_self'}
            rel='noreferrer'
            className={classes}>
            {text}
            <FontAwesomeIcon icon={icon || faArrowRight} size="lg" className='mx-2' />
        </a>
    )
}

export default Button